<template>
  <AuthLayout v-if="!success">
    <div class="auth">
      <h1 class="auth__title">Восстановление пароля</h1>
      <div class="auth__body">
        <form @submit.prevent="submit">
          <EmailInput
            v-model="form.email"
            :error="errors.email"
          />
          <BaseButton big color-primary :class="{ disabled: !form.email?.length, pending: loading }">Изменить пароль</BaseButton>
          <BaseButton class="auth__back" transparent :to="{ name: 'auth-login' }" replace><IconArrowLeft />Назад</BaseButton>
        </form>
      </div>
    </div>
  </AuthLayout>
  <StatusPage v-else>
    <template #icon><IconMailBrand /></template>
    <template #title>Ссылка для восстановления доступа отправлена на ваш E-mail</template>
    <template #desc>Мы отправили ссылку для смены пароля на указанный e-mail {{ form.email }}</template>
    <template #hint>Не забудьте проверить папку “Спам”, если письмо не пришло.</template>
  </StatusPage>
</template>

<script setup>
import { passwordRestoreRequest } from '@/api/auth'
import useForm from '~/composables/useForm'
import AuthLayout from '@/components/layouts/AuthLayout'
import { IconArrowLeft } from '@tabler/icons-vue'
import BaseButton from '@/components/common/BaseButton'
import EmailInput from '@/components/common/EmailInput'
import IconMailBrand from '@/components/icons/IconMailBrand'
import StatusPage from '@/components/layouts/StatusPage'

useHead({
  title: 'Восстановление пароля'
})

const success = ref(false)

const {
  form,
  errors,
  submit,
  loading
} = useForm(passwordRestoreRequest, async () => (success.value = true))
</script>
